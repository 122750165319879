import styled from "styled-components";

export const iconSize = 30;

export const PlatformMenuWrapper = styled.div`
  z-index: 15;
  position: fixed;
  left: 0;
  top: 0;
  width: 56px;
  padding-top: 4px;
  overflow: hidden;
  height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: ${({ theme }) => theme.palette.grey[100]};
  transition: width 100ms ease-in 400ms, box-shadow 100ms ease-in 400ms;
  &:hover {
    width: 140px;
    box-shadow: 0 0 120px rgba(0, 0, 0, 0.5);
  }
  a {
    text-decoration: none;
  }
`;

export const ListItem = styled.div`
  display: flex;
  padding: 4px 8px 4px 7px;
  align-items: center;
  width: 140px;
  color: black;
`;

export const IconWrap = styled.div<{
  round: boolean;
  activeBorderColor: string;
}>`
  margin-right: 10px;
  padding: 4px;
  width: ${iconSize + 12}px;
  height: ${iconSize + 12}px;
  border-radius: ${({ round }) => (round ? "100%" : "14px")};
  border: 2px solid transparent;
  transition: border-color 0.2s;
  &:hover {
    border-color: ${({ theme }) => theme.palette.grey[200]};
  }
  &.active {
    border-color: ${({ activeBorderColor }) => activeBorderColor};
  }
`;
